import React from 'react';
import s from './Loader.module.scss';
import classNames from "classnames";

interface ILoader {
    isHidden?: boolean
}
const Loader = ({isHidden}: ILoader) => {
    return (
        <div className={classNames(s.loader, isHidden && s.loader_hidden)}>
            <div className={s.roller}>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    );
};

export default Loader;