import React, {useEffect} from 'react';
import s from './Gallery.module.scss';
import GalleryPhoto from './../../assets/images/gallery.png';
import {Swiper, SwiperSlide, useSwiper} from "swiper/react";
import 'swiper/css';
import {Autoplay} from "swiper";
import classNames from "classnames";

interface IGallery {
    items?: any
    delay?: number
    isSmall?: boolean
}
const Gallery = ({items = [GalleryPhoto, GalleryPhoto], delay = 5000, isSmall}: IGallery) => {

    return (
        <div className={classNames(s.gallery, isSmall && s.gallery_small)}>
            {!isNaN(delay) && <Swiper className={s.gallery__slider} autoplay={{delay}} slidesPerView={1} spaceBetween={0} modules={[Autoplay]}>
                {items && items.map((i: any) => (
                    <SwiperSlide className={s['gallery__slider-item']} key={i}>
                        <img src={i} alt=""/>
                    </SwiperSlide>
                ))}
            </Swiper>}
        </div>
    );
};

export default Gallery;