import React from 'react';
import s from './Layout.module.scss';
import Sidebar from "../Sidebar/Sidebar";

interface ILayout {
    children: any
}
const Layout = ({children}: ILayout) => {
    return (
        <div className={s.layout}>
            <Sidebar/>
            <div className={s.layout__content}>
                {children}
            </div>
        </div>
    );
};

export default Layout;