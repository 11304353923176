import React, {useEffect, useState} from 'react';
import s from './Lesson.module.scss';
import classNames from "classnames";

interface ILesson {
    isAlt?: boolean
    isActive?: boolean
    title?: string
    date?: string
    timeout?: number
}
const Lesson = ({isAlt, isActive, title = '', date, timeout = 0}: ILesson) => {
    let [isAnimated, setIsAnimated] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setIsAnimated(true);
        }, timeout);
    }, []);

    return (
        <div className={classNames(s.lesson, isActive && s.lesson_active, isAlt && s.lesson_alt, isAnimated && s.lesson_animated)}>
            <span dangerouslySetInnerHTML={{__html: title}}></span>
            {date && <span>{date}</span>}
        </div>
    );
};

export default Lesson;